import React from 'react';
import '../Css/craft.css';

const Craft = () => {

    return (
        <div className="CraftWindow">
            <p>Craft</p>
        </div>
    );
};

export default Craft;